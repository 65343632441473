import theme from "assets/theme";
import getCroppedImg from "components/ImageCrop/cropFunc";
import { useState } from "react";
import Cropper from "react-easy-crop";

interface Props {
  image: any;
  onChange: (value: File) => void;
}

const CropImage = ({ image, onChange }: Props) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);

  const onCropComplete = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      const croppedImage: any = await getCroppedImg(image, croppedAreaPixels!, rotation);
      onChange(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 165,
          background: "#333",
          [theme.breakpoints.up("sm")]: {
            height: 200,
          },
        }}
      >
        <Cropper
          image={image?.dataURL}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </div>
  );
};

export default CropImage;
